const prod = {
  backendURL : '/haar',
  externalStaticURL : 'https://a-eun-1.archaeopticscloud.com:8644'
}

const dev = {
  backendURL : 'http://localhost:5013',
  externalStaticURL : 'http://localhost:8191'
}

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev

export default {
  ...config
}
