
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import './ArchiveSoloPageView.css';

import { Alert, Button, Card, Carousel, Col, Row, Table } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import axios from 'axios';

import InfoArea from './utils/InfoArea.js';
import NavBar from './NavBar.js';

import PermissionsTableView from './PermissionsTableView.js';

function ArchiveSoloPageView( { props } ) {

    const [ alertMessage, setAlertMessage ] = useState( '' );
    const [ alertNature, setAlertNature ] = useState( '' );
    const [ alertShow, setAlertShow ] = useState( false );

    const [ object, setObject ] = useState( {} );
    const [ display, setDisplay ] = useState( { 'ScanEpochs' : true } );
    const [ scanEpochPaths, setScanEpochPaths ] = useState( [ 'ScanEpochs' ] );

    let location = useLocation();
    let params = useParams();

    const updateScanEpochHeadings = scanEpochUUIDs => {
        var displayCopy = display;
        var scanEpochUUIDsCopy = scanEpochPaths;

        for ( var i = 0; i < scanEpochUUIDs.length; i++ ) {
          const splat = scanEpochUUIDs[i].split( '/' );
          const showName = splat[splat.length - 1];

          scanEpochUUIDsCopy.push( scanEpochUUIDs[i] );
          displayCopy[showName] = true;
        }

        setScanEpochPaths( scanEpochUUIDsCopy );
        setDisplay( displayCopy );
    };

    useEffect( () => {

        var url = '';

        if ( 'uuid' in params ) {
            url = props.config.backendURL + '/objects/' + params.uuid;
        } else {
            if ( 'mnemonic1' in params ) {
                url = props.config.backendURL + '/permalink/objects/' + params.mnemonic0 + '/' + params.mnemonic1;
            } else {
                if ( 'mnemonic0' in params ) {
                    url = props.config.backendURL + '/permalink/objects/' + params.mnemonic0;
                }
            }
        }

        axios.get( url )
          .then( res => {
            if ( res.status !== 200 ) {
              this.updateAlert( 'Failed to find archive item', 'danger' );
            } else {
              var result = res.data.result[0];
              var scanepochs = result['scanepochs'];

              var scanepochUUIDs = [];

              for ( var i = 0; i < scanepochs.length; i++ ) {
                scanepochUUIDs.push( 'ScanEpochs/' + scanepochs[i]['dateofscan'] );
                scanepochUUIDs.push( 'ScanEpochs/' + scanepochs[i]['dateofscan'] + '/' + scanepochs[i]['dateofscan'] + '_ModelsProcessed' );
                scanepochUUIDs.push( 'ScanEpochs/' + scanepochs[i]['dateofscan'] + '/' + scanepochs[i]['dateofscan'] + '_Media' );
                scanepochUUIDs.push( 'ScanEpochs/' + scanepochs[i]['dateofscan'] + '/' + scanepochs[i]['dateofscan'] + '_RawScanData' );
              }

              updateScanEpochHeadings( scanepochUUIDs );
              setObject( result );
            }
          } )
          .catch( error => {
            var lalertMessage = 'Something went wrong';

            if ( error.response ) {
              lalertMessage = error.response.data.message;
            }

            updateAlert( lalertMessage, 'danger' );
          } );
// eslint-disable-next-line          
      }, [ props.config.backendURL ] );

  // BASIC METHODS
    const updateAlert = ( message, nature ) => {
      setAlertMessage( message );
      setAlertNature( nature );
      setAlertShow( true );
    };

    const closeAlert = e => {
      setAlertShow( false );
      setAlertMessage( '' );
      setAlertNature( '' );
    };

  // IMAGE CAROUSEL
    const previewCarousel = e => {
      return (
        <Carousel>
          { object.previewimages && object.previewimages.map(
              previewimage => {
                var url = props.config.externalStaticURL + '/' + object.uuid + '/previewimages/' + previewimage.uuid + '.' + previewimage.suffix;
                return(
                  <Carousel.Item key={previewimage.uuid}>
                    <img src={url} alt="" style={{ width : '100%', height : '75%'}}/>
                  </Carousel.Item>
                );
              }
            )
          }
        </Carousel>
      );
    };

  // TABLE METHODS
    const getScanEpochTable = scanepoch => {

      if ( !scanepoch || !scanepoch.processedmodels ) {
        return(
          <React.Fragment>
            Processed model information is not permitted for viewing
          </React.Fragment>
        );
      }

      return (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Format</th>
              <th>Type</th>
              <th>Number of points</th>
              <th>Number of triangles</th>
              <th>RGB</th>
              <th>Watertight</th>
              <th>View</th>
            </tr>
          </thead>

          <tbody>
            { scanepoch.processedmodels && scanepoch.processedmodels.map(
                processedmodel => {
                  var hasRaw = (processedmodel && (processedmodel.noraw && processedmodel.noraw === false)) || !processedmodel.noraw;
                  return (
                    <tr key={scanepoch.dateofscan + processedmodel.uuid}>
                      <td>{processedmodel.filename}</td>
                      <td>{processedmodel.modelformat}</td>
                      <td>{processedmodel.modeltype}</td>
                      <td>{processedmodel.numberofpoints}</td>
                      <td>{processedmodel.numberoftriangles}</td>
                      <td>{processedmodel.rgb.toString()}</td>
                      <td>{processedmodel.watertight.toString()}</td>
                      <td>
                      { hasRaw &&
                        <Button 
                          variant='outline-secondary'
                          size='sm'
                          id={processedmodel.uuid} 
                          onClick={this.showObjViewer}>
                          <FaEye />
                        </Button>
                      }
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      );
    };


  // CARDS METHODS
    const getScanEpochMediaCards = scanepoch => {

      return (
        <React.Fragment>
          { scanepoch && scanepoch.media && scanepoch.media.sort( ( a, b ) => a['filename'] > b['filename'] ? 1 : -1 ).map(
              scan => {
                return (
                  <Card style={{ width: '18rem' }} key={'rawscancard' + scan.uuid} className='rawscancard'>
                    <Card.Img 
                      variant="top" 
                      src={props.config.externalStaticURL + '/' + object.uuid + '/media/thumbnails/' + scan.uuid + '.png'}
                      id={scan.uuid}
                    />
                    <Card.Body>
                      <Card.Title>{scan.filename.replace( '.jpg', '' )} ({scan.mediaformat})</Card.Title>
                      <Card.Text>
                        {scan.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )
              }
            )
          }
        </React.Fragment>
      );
    };

    const getScanEpochRawScanCards = scanepoch => {
      return (
        <React.Fragment>
          { scanepoch && scanepoch.rawscans && scanepoch.rawscans.sort( ( a, b ) => a['filename'] > b['filename'] ? 1 : -1 ).map(
              scan => {
                return (
                  <Card style={{ width: '18rem' }} key={'rawscancard' + scan.uuid} className='rawscancard'>
                    <Card.Img 
                      variant="top" 
                      src={props.config.externalStaticURL + '/' + object.uuid + '/archive/thumbnails/mesh/' + scan.uuid + '.png'}
                      id={scan.uuid}
                    />
                    <Card.Body>
                      <Card.Title>{scan.filename.replace( '.ply', '' )}</Card.Title>
                      <Card.Text>
                        <strong>Scanner:</strong> {scan.scanner}
                        <br/>
                        <strong>Resolution:</strong> {scan.xyresolution} mm
                        <br/>
                        <strong>Accuracy:</strong> {scan.zaccuracy} mm
                        <br/>
                        <strong>Structure:</strong> {scan.structure}
                        <br/>
                        <strong>Points:</strong> {scan.numberofpoints}
                        <br/>
                        <strong>Filesize:</strong> {( scan.filesize / Math.pow( 2, 20 ) ).toFixed( 1 )} MiB
                        <br/>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )
              }
            )
          }
        </React.Fragment>
      );
    };

    const getScanEpochRawImageCards = scanepoch => {

      return (
        <React.Fragment>
          { scanepoch && scanepoch.rawimages && scanepoch.rawimages.sort( ( a, b ) => a['filename'] > b['filename'] ? 1 : -1 ).map(
              scan => {
                return (
                  <Card style={{ width: '18rem' }} key={'rawimagecard' + scan.uuid} className='rawimagecard'>
                    <Card.Img 
                      variant="top" 
                      src={props.config.externalStaticURL + '/' + object.uuid + '/archive/thumbnails/rgb/' + scan.uuid + '.png'}
                      id={scan.uuid}
                    />
                    <Card.Body>
                      <Card.Title>{scan.filename.replace( '.png', '' )}</Card.Title>
                      <Card.Text>
                        <strong>Width:</strong> {scan.width} pixels
                        <br/>
                        <strong>Height:</strong> {scan.height} pixels
                        <br/>
                        <strong>Structure:</strong> {scan.structure}
                        <br/>
                        <strong>Format:</strong> {scan.modelformat}
                        <br/>
                        <strong>Filesize:</strong> {( scan.filesize / Math.pow( 2, 20 ) ).toFixed( 1 )} MiB
                        <br/>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )
              }
            )
          }
        </React.Fragment>
      );
    }; 

  // COLLAPSE METHODS
    const setParentDisplay = (k, v) => {
      var ldisplay = { ...display };
      ldisplay[k] = v;
      setDisplay( ldisplay );
    };

    const getScanEpochsInfoAreas = e => {
      return (
        <React.Fragment>
          { object.scanepochs &&
            <React.Fragment>
              { object.scanepochs.sort( ( a, b ) => a['dateofscan'] > b['dateofscan'] ? 1 : -1 ).map(
                  scanepoch => {
                    return (
                      <InfoArea callback={setParentDisplay} title={scanepoch.dateofscan} depth={2} key={scanepoch.dateofscan} uuid={scanepoch.dateofscan} display={display}>
                        <InfoArea callback={setParentDisplay} title={'Processed Models'} depth={3} key={scanepoch.dateofscan + '_ModelsProcessed'} uuid={scanepoch.dateofscan + '_ModelsProcessed'} display={display}>
                          <div className='info-body white'>
                            {object.permissions && object.permissions.processed ? getScanEpochTable( scanepoch ) : 'Processed models are not permitted for viewing' }
                          </div>
                        </InfoArea>
                        <InfoArea callback={setParentDisplay} title={'Media'} depth={3} key={scanepoch.dateofscan + '_Media'} uuid={scanepoch.dateofscan + '_Media'} display={display}>
                          <div className='info-body white'>
                            <div className='card-scroller'>
                              {object.permissions && object.permissions.media ? getScanEpochMediaCards( scanepoch ) : 'Media previews are not permittd for viewing' }
                            </div>
                          </div>
                        </InfoArea>
                        <InfoArea callback={setParentDisplay} title={'Raw Capture Data'} depth={3} key={scanepoch.dateofscan + '_RawScanData'} uuid={scanepoch.dateofscan + '_RawScanData'} display={display}>
                          <div className='info-body white'>
                            <div className='card-scroller'>
                              {object.permissions && object.permissions.rawpreview ? getScanEpochRawScanCards( scanepoch ) : 'Raw scan previews are not permitted for viewing' }
                              {object.permissions && object.permissions.rawpreview ? getScanEpochRawImageCards( scanepoch ) : 'Raw image previews are not permitted for viewing' }
                            </div>
                          </div>
                        </InfoArea>
                      </InfoArea>
                    );
                  }
                )
              }
            </React.Fragment>
          }
        </React.Fragment>
      );
    };

  // RENDER
  return (
      <React.Fragment>
        <NavBar pathname={location.pathname} />

        <div className='mainbody'>
          <Alert variant={alertNature} onClose={closeAlert} show={alertShow} dismissible>
            <p className='alertbody'>
              {alertMessage}
            </p>
          </Alert>

          <Row>
            <Col>
              <Row>
                <Col>
                  <h2>{object.name}</h2>
                  <br/>
                  <b>Permalink:</b> {'https://archive.archaeopticscloud.com/permalink/objects/' + object.path}
                  <p/>
                </Col>
              </Row>

              { object && object.assemblage &&
                  <Row>
                  <Col>
                    This object is part of the <a href={'/assemblages/' + object.assemblageuuid}>{object.assemblage}</a> assemblage.
                    <p/>
                  </Col>
                </Row>
              }

              <Row>
                <Col>
                    <b>Description</b>
                    <p/>
                  {object.description}
                  <p/>
                </Col>
              </Row>

              <Row>
                <Col>
                    <b>Tags</b>
                    <p/>
                    { object && object.tags && object.tags.map(
                        tag => {
                          return (
                            <div className='tag' key={object.uuid + tag}>
                                <a href={'/tags/' + tag}>{tag}</a>
                            </div>
                          );
                        }
                      )
                    }
                </Col>
              </Row>

              <Row>
                <Col>
                  <p/>
                  <b>External Links</b>
                  <p/>
                </Col>
              </Row>
              <Row>
                <Col>
                    { object && object.links && Object.keys(object.links).map(
                        (pkey, pindex) => {
                          var plink = object.links[pkey];
                          /** Handle specific location keyed links */
                          if ( object.location ) {
                              if ( pkey === 'megportal' ) {
                                  if ( plink === true ) {
                                      return (
                                        <div className='tag' key={object.uuid + 'megportal'}>
                                          <a href={'https://www.megalithic.co.uk/article.php?lat=' + object.location.latitude + '&lon=' + object.location.longitude} target="_offsite">Megalithic Portal</a>
                                        </div>
                                      );
                                  } else {
                                    return( <div></div> );
                                  }
                              } else {
                                if ( pkey === 'symbolstones' ) {
                                    if ( plink === true ) {
                                        return (
                                          <div className='tag' key={object.uuid + 'symbolstones'}>
                                            <a href={'https://symbolstones.archaeoptics.co.uk/stones/nearest/' + object.location.latitude + '/' + object.location.longitude} target="_offsite">Symbolstones Database</a>
                                          </div>
                                        );
                                    } else {
                                      return( <div></div> );
                                    }
                                }
                              }
                          }

                          /** Loose links */                            
                          return (
                            <div className='tag' key={object.uuid + '_' + pkey}>
                                <a href={plink} target='_offsite'>{pkey}</a>
                            </div>
                          );
                        }
                      )
                    }
                    <p/>
                </Col>
              </Row>
            </Col>
            <Col>
              {previewCarousel()}
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>             
                  <PermissionsTableView props={object} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoArea callback={setParentDisplay} title={'Scan Epochs'} depth={1} uuid={'ScanEpochs'} display={display}>
                {getScanEpochsInfoAreas()}
              </InfoArea>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

export default ArchiveSoloPageView;
