
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';

import './LandingPageView.css';

import { Col, Row, Table } from 'react-bootstrap';

import Footer from './Footer.js';
import NavBar from './NavBar.js';

function LandingPageView( { props } ) {

    const [ statistics, setStatistics ] = useState( {} );
    const [ alertMessage, setAlertMessage ] = useState( '' );
    const [ alertNature, setAlertNature ] = useState( '' );
    const [ alertShow, setAlertShow ] = useState( false );

    let location = useLocation();

    useEffect( () => {
      axios.get( props.config.backendURL + '/statistics' )
        .then( res => {
          if ( res.status !== 200 ) {
            updateAlert( 'Failed to retrieve statistics', 'danger' );
          } else {
            var result = res.data.result[0];
            setStatistics( result );
          }
        } )
        .catch( error => {
          var lalertMessage = 'Something went wrong';

          if ( error.response ) {
            lalertMessage = error.response.data.message;
          }

          updateAlert( lalertMessage, 'danger' );
        } );
    }, [ props.config.backendURL ] );

    const updateAlert = ( message, nature ) => {
      setAlertMessage( message );
      setAlertNature( nature );
      setAlertShow( true );
    }

    const closeAlert = e => {
      setAlertShow( false );
      setAlertMessage( '' );
      setAlertNature( '' );
    }

  // RENDER
  return (
    <React.Fragment>
      <NavBar pathname={location.pathname} />
      
      <div className='mainbody'>
        <Row>
        <Col xs={6}>
          <Row className="mt-2">
            <Col>
              <h2>Archaeoptics Archive</h2>
            </Col>
          </Row>
          <Row>
            <Col>
            <b>Welcome to the Archaeoptics Archive!</b>
            <p/>
            Between 2000 and 2006, Archaeoptics pioneered the use of 3D laser scanning technology within the cultural heritage sector.
            <p/>
            Following the original mission statement of "recording the past for the future", Archaeoptics are now presenting the
            entirety of their high-quality 3D laser scanning and photogrammetry archive for research purposes
            and posterity. Workflows, papers, methodologies, software and algorithms will be added to
            the Archive over time.
            <p/>
            The Archive can be accessed either by a listing of all <a href="/objects">Objects</a> or by all <a href="/assemblages">Assemblages</a>.
            <p/>
            The Archive represents a snapshot of heritage usually prior to significant works. For example,
            the Cutty Sark prior to its latest incarnation, St. Paul's Cathedral during conservation and the
            Mary Rose in its final stages of conservation. The Archive also contains a lot of "firsts" in terms of
            both data capture, methodology and discovery of new knowledge including Stonehenge
            (see our <a href={'https://stonehenge.archaeoptics.co.uk'} target="_offsite">microsite</a>),
              Maeshowe and Aberlemno.
              <p/>
              A number of public artworks have also been captured including ephemeral works such as chainsaw
              wood carvings. Some of these artworks were produced from our data, for example, some works by
             <a href={'https://simonwatkinson.com'} target="_offsite"> Simon Watkinson</a>, and some artworks are captures of
              finished artwork in the public space.
            <p/>
            There is a large quantity of data in the archive, but please read the <a href="/data">Licensing</a> page for more detailed information on this subject.
            <p/>
            Please <a href={'https://archaeoptics.co.uk'} target="_offsite">visit our main site</a> for other resources and information about our products and services.
            We are active on <a href={'https://twitter.com/archaeoptics3d'} target="_offsite">Twitter </a>
            so follow us there to get frequent updates and posts from the archives!
            <p/>
            We hope you enjoy the Archive.
            <p/>
            </Col>
          </Row>

          <Row>
            <Col>
            <b>Database Statistics</b>
              <p/>
              <Table striped bordered size='sm'>
                <thead>
                </thead>
                <tbody>

                <tr>
                  <td>
                    Number of Assemblages
                  </td>
                  <td>
                    { statistics && statistics.numassemblages ?
                      statistics.numassemblages :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Number of Objects
                  </td>
                  <td>
                    { statistics && statistics.numobjects ?
                      statistics.numobjects :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Number of 3D Models
                  </td>
                  <td>
                    { statistics && statistics.nummodels ?
                      statistics.nummodels :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Number of Raw 3D Scans
                  </td>
                  <td>
                    { statistics && statistics.numrawscans ?
                      statistics.numrawscans :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Number of Raw Photogrammetry Images
                  </td>
                  <td>
                    { statistics && statistics.numrawimages ?
                      statistics.numrawimages :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Archive Size (Compressed, Gb)
                  </td>
                  <td>
                    { statistics && statistics.archivesizeinbytes_compressed ?
                      Math.ceil( statistics.archivesizeinbytes_compressed / 1024 / 1024 / 1024) :
                      'Unknown'
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    Archive Size (Decompressed, Gb)
                  </td>
                  <td>
                    { statistics && statistics.archivesizeinbytes_decompressed ?
                      Math.ceil( statistics.archivesizeinbytes_decompressed / 1024 / 1024 / 1024 ) :
                      'Unknown'
                    }
                  </td>
                </tr>

                </tbody>
              </Table>

            </Col>
          </Row>

        </Col>
        <Col>
          <div className="mt-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
          <img alt="Archaeoptics" height="250" src="assets/archaeoptics_brand.png"/>
          </div>
        </Col>
        </Row>

      </div>

      <Footer />
      </React.Fragment>
  );
};

export default LandingPageView;