
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import './ArchiveSoloPageView.css';

import { Alert, Card, Carousel, Col, Row, Table } from 'react-bootstrap';
import axios from 'axios';

import InfoArea from './utils/InfoArea.js';
import NavBar from './NavBar.js';

import * as AuthUtils from './utils/AuthUtils.js';

import PermissionsTableView from './PermissionsTableView.js';

function AssemblageSoloPageView( { props } ) {

    const [ alertMessage, setAlertMessage ] = useState( '' );
    const [ alertNature, setAlertNature ] = useState( '' );
    const [ alertShow, setAlertShow ] = useState( false );

    const [ assemblage, setAssemblage ] = useState( {} );
    const [ display, setDisplay ] =
        useState( {
            '_Media' : true,
            '_Objects' : true
        } );

    let location = useLocation();  
    let params = useParams();  

    useEffect( () => {

        var url = '';

        if ( 'uuid' in params ) {
            url = props.config.backendURL + '/assemblages/' + params.uuid;
        } else {
            if ( 'mnemonic1' in params ) {
                url = props.config.backendURL + '/permalink/assemblages/' + params.mnemonic0 + '/' + params.mnemonic1;
            } else {
                if ( 'mnemonic0' in params ) {
                    url = props.config.backendURL + '/permalink/assemblages/' + params.mnemonic0;
                }
            }
        }

      axios.get( url )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find archive item', 'danger' );
          } else {
            var result = res.data.result[0];

            setAssemblage( result );
          }
        } )
        .catch( error => {
          console.log( error )
          var lalertMessage = 'Something went wrong';

          if ( error.response ) {
            lalertMessage = error.response.data.message;
          }

          updateAlert( lalertMessage, 'danger' );
        } );
    }, [ location ] );

  // BASIC METHODS
    const updateAlert = ( message, nature ) => {
      setAlertMessage( message );
      setAlertNature( nature );
      setAlertShow( true );
    };

    const closeAlert = e => {
      setAlertShow( false );
      setAlertMessage( '' );
      setAlertNature( '' );
    };

  // COLLAPSE METHODS
    const setParentDisplay = (k, v) => {
      var ldisplay = { ...display };
      ldisplay[k] = v;
      setDisplay( ldisplay );
    };

  // IMAGE CAROUSEL
   const previewCarousel = e => {
      return (
        <Carousel>
          { assemblage.previewimages && assemblage.previewimages.map(
              previewimage => {
                var url = props.config.externalStaticURL + '/' + assemblage.uuid + '/previewimages/' + previewimage.uuid + '.' + previewimage.suffix;
                return(
                  <Carousel.Item key={previewimage.uuid}>
                    <img src={url} alt="" style={{ width : '480px'}}/>
                  </Carousel.Item>
                );
              }
            )
          }
        </Carousel>
      );
    };

    // EVENT HANDLING
    const handleRowClick = ( e, uuid ) => {
      window.location.href = '/objects/' + e.currentTarget.id;
    };

  // TABLE METHODS
    const getObjectsTable = e => {

      return (
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Preview Image</th>
              <th>Name</th>
              <th>Dates of Scans</th>
            </tr>
          </thead>

          <tbody>
            { assemblage.objects && assemblage.objects.map(
                lobject => {
                  return (
                    <tr key={lobject.objectuuid} onClick={handleRowClick} id={lobject.objectuuid} className='archive-full-body'>
                      { lobject.previewimage ?
                        <td>
                          <img src={props.config.externalStaticURL + '/' + lobject.objectuuid + '/previewimages/' + lobject.previewimage.uuid + '.' + lobject.previewimage.suffix} alt="Preview of object" style={{ height : '100px', borderRadius : '7px'}}/>
                        </td>
                      :
                        <td>
                          Image missing
                        </td>
                      }
                      <td>{lobject.objectname}</td>
                      <td>
                        { lobject.scanepochs.map(
                            tag => {
                              return (
                                <div key={lobject.objectuuid + tag}>
                                  {tag}
                                </div>
                              );
                            }
                          )
                        }
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      );
    };

  // COLLAPSE METHODS

  // CARDS METHODS
    const getMediaCards = e => {
      if ( !assemblage || !assemblage.permissions || !assemblage.permissions.media ) {
        return (
          <React.Fragment>
            Media not permitted for viewing
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          { assemblage.media && assemblage.media.map(
              media => {
                return (
                  <Card style={{ width: '18rem' }} key={'mediacard' + media.uuid} className='mediacard'>
                    <Card.Img 
                      variant="top" 
                      src={props.config.externalStaticURL + '/' + assemblage.uuid + '/media/thumbnails/' + media.uuid + '.png'}
                      id={media.uuid}
                    />
                    <Card.Body>
                      <Card.Title>{media.filename.replace( '.png', '' )} ({media.mediaformat})</Card.Title>
                      <Card.Text>
                        {media.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )
              }
            )
          }
        </React.Fragment>
      );
    };

  // RENDER
    return (
        <React.Fragment>
          <NavBar pathname={location.pathname}/>

          <div className='mainbody'>
            <Alert variant={alertNature} onClose={closeAlert} show={alertShow} dismissible>
              <p className='alertbody'>
                {alertMessage}
              </p>
            </Alert>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <h2>{assemblage.name}</h2>
                    <b>Permalink:</b> {'https://archive.archaeopticscloud.com/permalink/assemblages/' + assemblage.path}
                    <p/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {assemblage.description}
                    <p/>
                  </Col>
                </Row>

                <Row>
                  <Col>
                      <b>Tags</b>
                      <p/>
                      { assemblage && assemblage.tags && assemblage.tags.map(
                          tag => {
                            return (
                              <div className='tag' key={'tag' + assemblage.uuid + tag}>
                                <a href={'/tags/' + tag}>{tag}</a>
                              </div>
                            );
                          }
                        )
                      }
                      <p/>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <b>External Links</b>
                    <p/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                      { assemblage && assemblage.links && Object.keys(assemblage.links).map(
                          (pkey, pindex) => {
                            var plink = assemblage.links[pkey];
                            /** Handle specific location keyed links */
                            if ( assemblage.location ) {
                                if ( pkey === 'megportal' ) {
                                    if ( plink === true ) {
                                        return (
                                          <div className='tag' key={assemblage.uuid + 'megportal'}>
                                            <a href={'https://www.megalithic.co.uk/article.php?lat=' + assemblage.location.latitude + '&lon=' + assemblage.location.longitude} target="_offsite">Megalithic Portal</a>
                                          </div>
                                        );
                                    } else {
                                      return( <div></div> );
                                    }
                                } else {
                                  if ( pkey === 'symbolstones' ) {
                                      if ( plink === true ) {
                                          return (
                                            <div className='tag' key={assemblage.uuid + 'symbolstones'}>
                                              <a href={'https://symbolstones.archaeoptics.co.uk/stones/nearest/' + assemblage.location.latitude + '/' + assemblage.location.longitude} target="_offsite">Symbolstones Database</a>
                                            </div>
                                          );
                                      } else {
                                        return( <div></div> );
                                      }
                                  }
                                }
                            }

                            /** Loose links */
                            if ( pkey !== 'megportal' && pkey !== 'symbolstones' ) {
                                return (
                                  <div className='tag' key={assemblage.uuid + '_' + pkey}>
                                      <a href={plink} target='_offsite'>{pkey}</a>
                                  </div>
                                );
                            } else {
                              return( <div></div> );
                            }
                          }
                        )
                      }
                    <p/>
                  </Col>
                </Row>            
              </Col>
              <Col>
                {previewCarousel()}
              </Col>
            </Row>
            <Row>
              <Col>
                <PermissionsTableView props={assemblage} />
              </Col>
            </Row>
            <Row>
              <Col>
                <InfoArea callback={setParentDisplay} title={'Objects'} depth={3} key={'_Objects'} uuid={'_Objects'} display={display}>
                  <div className='info-body white'>
                    <div className='card-scroller'>
                      {getObjectsTable()}
                    </div>
                  </div>
                </InfoArea>
              </Col>
            </Row>
            <Row>
              <Col>
                <InfoArea callback={setParentDisplay} title={'Media'} depth={3} key={'_Media'} uuid={'_Media'} display={display}>
                  <div className='info-body white'>
                    <div className='card-scroller'>
                      {getMediaCards()}
                    </div>
                  </div>
                </InfoArea>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
};

export default AssemblageSoloPageView;
