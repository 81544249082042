
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './AboutPageView.css';

import { Col, Row } from 'react-bootstrap';

import Footer from './Footer.js';
import NavBar from './NavBar.js';

function DataPageView( { props } ) {

    let location = useLocation();

  // RENDER
    return (
      <React.Fragment>
        <NavBar pathname={location.pathname} />
        
        <div className='mainbody'>
          <Row>
          <Col xs={6}>
            <Row className="mt-2">
              <Col>
                <h2>Access to Archaeoptics Data</h2>
              </Col>
            </Row>
            <Row>
              <Col>
              Archaeoptics were unusual in our default position with respect to data. Our view was simply that the paying customer should own the data rights.
              The rationale here was the data could be better disseminated through their existing collections systems.
              <p/>
              However, as with all great ideas, this turned out to be optimistic. Most organisations at the time had no capability for managing large
              volumes of 3D data and most of our shipped data has languished in boxes and cupboards or, quite often, been completely lost.
              <p/>
              The knock-on effect is also that the information about what we actually scanned has been unavailable as well. The Archaeoptics Archive is designed to
              help rectify this.
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h2>Data Downloads</h2>
              </Col>
            </Row>
            <Row>
              <Col>
              Due to the Archaeoptics Archive being self-funded, we have decided to not provide automated data downloads from the archive
              due to online storage costs.
              <p/>
              Data can be requested if the IP Owner allows this. Please read the following section for more details.
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h2>Data Ownership / Rights</h2>
              </Col>
            </Row>
            <Row>
              <Col>
              Unfortunately, our well-intended approach has inadvertently created a complex landscape of data rights.
              <p/>
              We have liaised with all the IP Owners listed in the Archive and established rights for the data for each object.
              <p/>
              Within the listing page for each object, there is a section entitled "Licensing and Data Access". This lists the
              attributions you must use if you reference or use the data. It lists the IP Owner and Site Owner. There is also
              a link for "Data Accessibility" and licensing of any imagery in the Archive.
              <p/>
              There is also a section entitled "Permissions" which lists the specific permissions for each object. These have
              been set by the IP Owner.
              <p/>
              For data downloads, if the dataset has been permitted to be made available, there is a link to the Data Request form.
              Please complete this and your data access request will be evaluated either by Archaeoptics or by the IP Owner.
              If the request is approved, you will be sent a link to download the data.
              <p/>
              There is no guarantee your request will be approved.
              <p/>
              Please <a href="https://archaeoptics.co.uk">visit our main site</a> for other resources and information about our products and services.
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h2>Copyright Notice</h2>
              </Col>
            </Row>
            <Row >
              <Col>
                All images and data remain the property of Archaeoptics, unless mentioned otherwise. Please do not copy or distribute any of the resources. If you are interested in access to the data, please <a href={'https://archaeoptics.co.uk/contact.html'} target="_offsite">contact us</a>.
              </Col>
            </Row>
          </Col>
          <Col>
            <div className="mt-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <img alt="Archaeoptics" height="600" src="assets/04042002_kirkyard_front_bwink.jpg"/>
            </div>
          </Col>
          </Row>
        </div>
        <Footer />
      </React.Fragment>
    );
}

export default DataPageView;

