
import React, { useState, useEffect } from 'react';

import { Table } from 'react-bootstrap';
import InfoArea from './utils/InfoArea.js';

function PermissionsTableView( { props } ) {

    const [ display, setDisplay ] =
      useState( {
          'Licensing and Data Access' : false,
          'Attributions and Data Access' : false,
          'Permissions' : false
      } );

    var cols = [
      [ 'record', 'Record Visible?' ],
      [ 'previewimages', 'Preview Images Visible?' ],
      [ 'media', 'Media Visible?' ],
      [ 'rawpreview', 'Raw Data Visible?' ],
      [ 'processed', 'Processed Data Visible?' ],
      [ 'download', 'Data Available for Download?']
    ];

  // PERMISSIONS TABLE
    var lipOwner = '';
    if ( props.ipowner === 'Archaeoptics - Contractor Liquidated' ) {
        lipOwner = 'Archaeoptics';
    } else {
        lipOwner = props.ipowner;
    }

    /** Compute courtesy of here so we avoid "Archaeoptics and Archaeoptics" */
    var attribution = '';
    if ( props.permissions && props.permissions.attribution ) {
        /** We have a custom attribution message */
        attribution = "\"" + props.permissions.attribution + "\"";
    } else {
        var courtesyOf = '';
        if ( lipOwner === 'Archaeoptics' ) {
            courtesyOf = 'Archaeoptics';
        } else {
            courtesyOf = 'Archaeoptics and ' + lipOwner;
        }

        attribution = "\"Data capture and processing by Archaeoptics (https://archaeoptics.co.uk). Source data copyright " + lipOwner + ". Reproduced courtesy of " + courtesyOf + ".\"";
    }

		// this.state.setParentState = this.setParentState.bind( this );

  // COLLAPSE METHODS
    const setParentDisplay = (k, v) => {
      var ldisplay = { ...display };
      ldisplay[k] = v;
      setDisplay( ldisplay );
    };

    return (
        <InfoArea callback={setParentDisplay} title='Licensing and Data Access' depth={0} key='Licensing and Data Access' uuid='Licensing and Data Access' display={display} >
          <InfoArea callback={setParentDisplay} title='Attributions and Data Access' depth={1} key='Attributions and Data Access' uuid='Attributions and Data Access' display={display} >
            <Table striped bordered hover size='sm'>
            	<tbody>
	              <tr>
	                <td><b>Site Owner</b></td>
	                <td>{props.siteowner}</td>
	              </tr>
	              <tr>
	                <td><b>IP Owner</b></td>
	                <td>{props.ipowner}</td>
	              </tr>
	              <tr>
	                <td><b>Attribution</b></td>
	                <td>{attribution}</td>
	              </tr>
	              <tr>
	                <td><b>Data Availability</b></td>
	                <td>{ props.permissions && props.permissions.download !== 'no' ?
	                      <a href={'/datarequest/' + props.path}>Contact IP owner to request access</a>
	                      :
	                      <a href={'/datarequest/' + props.path}>Data Unavailable</a>
	                    }
	                </td>
	              </tr>
	              <tr>
	                <td><b>Image Licensing</b></td>
	                <td>Please <a href="https://www.archaeoptics.co.uk/contact.html" target="_offsite">Contact Us</a></td>
	              </tr>
	            </tbody>
            </Table>
          </InfoArea>

          <InfoArea callback={setParentDisplay} title='Permissions' depth={1} key='Permissions' uuid='Permissions' display={display} >
            <Table striped bordered hover size='sm'>
              <tbody>
              { cols.map( k => {
                  var v = '';
                  if ( props.permissions ) {
                    if ( k[0] !== 'download' ) {
                        if ( props.permissions[k[0]] ) {
                            v = 'Yes';
                        } else {
                            v = 'No';
                        }
                    } else {
                      if ( props.permissions[k[0]] === 'no' ) {
                        v = 'No';
                      } else {
                        if ( props.permissions[k[0]] === 'yes' ) {
                          v = 'Yes';
                        } else {
                          v = 'Third Party Approval Required';
                        }
                      }
                    }
                  } else {
                    v = 'Unknown';
                  }

                  return(
                    <tr key={k[1]}>
                      <td><b>{k[1]}</b></td>
                      <td>{ v }</td>
                    </tr>
                  );
                } )
              }
              </tbody>
            </Table>
          </InfoArea>
        </InfoArea>
    );
};

export default PermissionsTableView;

