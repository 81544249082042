
import React from 'react';

import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import './InfoArea.css';

function InfoArea( props ) {

  const toggleCollapse = e => {
    props.callback( props.uuid, !props.display[props.uuid] );
  }

  return (
      <div id={props.uuid} className={ 'info-field depth' + props.depth }>
        <div className={ 'info-title depth' + props.depth }>
          <Row>
            <Col>
              <h4>
                {props.title}
              </h4>
            </Col>
            <Col>
              <Button size='sm' variant='outline-secondary' style={{float : 'right'}} onClick={toggleCollapse}>
                { props.display[props.uuid] ?
                  <FaChevronUp/>
                :
                  <FaChevronDown/>
                }
              </Button>
            </Col>
          </Row>
        </div>
        <Collapse in={ props.display[props.uuid] }>
          <div className='info-body'>
              {props.children}
          </div>
        </Collapse>
      </div>
    );
};

export default InfoArea;

