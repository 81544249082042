import React from 'react';

import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import AboutPageView from './components/AboutPageView';
import ArchiveBaseListPageView from './components/ArchiveBaseListPageView';
import ArchiveSearchPageView from './components/ArchiveSearchPageView';
import ArchiveSoloPageView from './components/ArchiveSoloPageView';
import AssemblageSoloPageView from './components/AssemblageSoloPageView';
import DataPageView from './components/DataPageView';
import DataRequestPageView from './components/DataRequestPageView';
import LandingPageView from './components/LandingPageView';
import StatisticsView from './components/StatisticsView';

import * as AuthUtils from './components/utils/AuthUtils.js';
import config from './config.js';

export default class App extends React.Component {
  // AUTHENTICATION METHODS
    authenticateSession = ( isAuthenticated, userInfo ) => {
      this.setState( { isAuthenticated : isAuthenticated, userInfo : Object.assign( {}, userInfo ) } );
      if ( isAuthenticated && 'access_token' in userInfo ) {
        /** Store refresh token to local storage */
        sessionStorage.setItem( 'userInfo', JSON.stringify( userInfo ) );
        axios.defaults.headers.common['Authorization'] = userInfo.access_token;
      } else {
        /** Clear local storage */
        sessionStorage.removeItem( 'userInfo' );
      }
    }

    deauthenticateSession = () => {
      this.setState( this.blankState );
      AuthUtils.deauthenticateSession();
    }

    updateAuthenticateSession = new_access_token => {
      if ( new_access_token ) {
        /** Store refresh token to local storage */
        var userInfoSession = sessionStorage.getItem( 'userInfo');
        userInfoSession = JSON.parse( userInfoSession );
        userInfoSession['access_token'] = new_access_token
        sessionStorage.setItem( 'userInfo', JSON.stringify( userInfoSession ) );

        axios.defaults.headers.common['Authorization'] = new_access_token;

        this.setState( { isAuthenticated : true, userInfo : Object.assign( userInfoSession ) } );
      } 
    }

    logout = props => {
      this.deauthenticateSession();
      if ( props && 'history' in props ) {
        props.history.push( '/login' );
      }
    }

  // RENDER
    render() {
      const childProps = {
        authenticateSession : this.authenticateSession,
        deauthenticateSession : this.deauthenticateSession,
        updateAuthenticateSession : this.updateAuthenticateSession,
        logout: this.logout,
        config : config
      };

      const objectsDataProps = {
        rootURL : '/objects',
        title : 'Objects'
      };

      const assemblagesDataProps = {
        rootURL : '/assemblages',
        title : 'Assemblages'
      };

      const tagsDataProps = {
        rootURL : '/tags',
        title : 'Tag: '
      };

      const siteOwnerDataProps = {
        rootURL : '/siteowner',
        title : 'Site Owner: '
      };

      const ipOwnerDataProps = {
        rootURL : '/ipowner',
        title : 'IP Owner: '
      };

      return (
        <React.Fragment>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<LandingPageView props={childProps} />} />

              <Route exact path="/about" element={<AboutPageView props={childProps} />} />

              <Route exact path="/data" element={<DataPageView props={childProps} />} />
              <Route path="/datarequest/*" element={<DataRequestPageView props={childProps} />} />

              <Route exact path="/objects" element={<ArchiveBaseListPageView props={childProps} dataProps={objectsDataProps} />} />
              <Route exact path="/objects/search/:searchquery" element={<ArchiveSearchPageView props={childProps} />} />
              <Route exact path="/objects/:uuid" element={<ArchiveSoloPageView props={childProps} />} />
              <Route exact path="/permalink/objects/:mnemonic0" element={<ArchiveSoloPageView props={childProps} />} />
              <Route exact path="/permalink/objects/:mnemonic0/:mnemonic1" element={<ArchiveSoloPageView props={childProps} />} />

              <Route exact path="/assemblages" element={<ArchiveBaseListPageView props={childProps} dataProps={assemblagesDataProps} />} />
              <Route path="/assemblages/:uuid" element={<AssemblageSoloPageView props={childProps} />} />
              <Route exact path="/permalink/assemblages/:mnemonic0" element={<AssemblageSoloPageView props={childProps} />} />
              <Route exact path="/permalink/assemblages/:mnemonic0/:mnemonic1" element={<AssemblageSoloPageView props={childProps} />} />

              <Route exact path="/stats" element={<StatisticsView props={childProps} />} />

              <Route path="/tags/:tag" element={<ArchiveBaseListPageView props={childProps} dataProps={tagsDataProps} />} />

              <Route path="/siteowner/:siteowner" element={<ArchiveBaseListPageView props={childProps} dataProps={siteOwnerDataProps} />} />
              
              <Route path="/ipowner/:ipowner" element={<ArchiveBaseListPageView props={childProps} dataProps={ipOwnerDataProps} />} />


            </Routes>
          </BrowserRouter>
        </React.Fragment>
      );
    }
}
