import React from 'react';

import { Alert, Button, ButtonGroup, Form, Nav, Navbar } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

import * as AuthUtils from './utils/AuthUtils.js';
import config from '../config.js';

export default class NavBar extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = AuthUtils.getAuthenticationState();

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state['searchValue'] = 'null';
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // SEARCH METHODS
    handleSearchSubmit = e => {
      e.preventDefault(); // stops page navigating away to the hash thing

      var searchValue = this.state.searchValue;

      if ( searchValue === '' ) {
        searchValue = 'null';
      }
      
      window.location.href = '/objects/search/' + searchValue;
    }

    handleLoadOptions = ( inputValue, callback ) => {
      var sanitisedValue = inputValue.trim().replace( ' ', '%20' );

      if ( sanitisedValue === '' ) {
        sanitisedValue = 'null';
      }

      axios.get( config.backendURL + '/objects/search/brief/' + sanitisedValue )
        .then( res => {
          if ( res.status ) {
            callback( res.data.result );
          }
        } )
        .catch( error => {
          var err = 'Failed to get search results from server.';

          if ( error.response ){
            err += '\nReason: ';
            err += error.response.data.message;
          }

          this.updateAlert( err, 'danger' );
          
          callback( [] );
        } );
    }

    handleSearchUpdate = inputValue => {
      if ( inputValue !== '' ) {
        this.setState( { searchValue : inputValue } );
      }
    }

    handleSearchChange = e => {
      window.location.href = '/objects/' + e.uuid;
    }

    handleSearchKeyDown = e => {
      if ( e.key === 'Enter' ) {
        this.handleSearchSubmit( e );
      }
    }

  // RENDER
    render() {
      return (
        <Navbar bg='light'>
          <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
            <p className='alertbody'>
              {this.state.alertMessage}
            </p>
          </Alert>
          <Navbar.Brand href='/'>
            <img
              src='/assets/archaeoptics_brand.png'
              width='30'
              height='30'
              className='d-inline-block align-top'
              alt='Archaeoptics logo'
            />
            {' '}
            Archaeoptics Archive
          </Navbar.Brand>
          <Nav className='mr-auto' defaultActiveKey={this.props.pathname}>
            <Nav.Item>
              <Nav.Link href='/objects' key='/objects'>
                Objects
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/assemblages' key='/assemblages'>
                Assemblages
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/data' key='/data'>
                Access
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/stats' key='/stats'>
                Statistics
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/about' key='/'>
                About
              </Nav.Link>
            </Nav.Item>
            {/*
            { this.state.isAuthenticated ?
              <React.Fragment>
                <Nav.Item>
                  <div style={{paddingLeft : '8px', paddingTop : '8px', paddingBottom : '8px'}}>
                    Welcome,{'\xa0'}
                    <a href='/user' key='/user'>
                      <strong>{this.state.userInfo.realname}</strong>
                    </a>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/login' key='/login' onClick={e => { AuthUtils.deauthenticateSession(); }}>
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </React.Fragment>
            :
              <Nav.Item>
                <Nav.Link href='/login' key='/login'>
                  Login
                </Nav.Link>
              </Nav.Item>
            } */}
          </Nav>
          <Form inline="true" onSubmit={this.handleSearchSubmit}>
            <ButtonGroup>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.handleLoadOptions}
                onInputChange={this.handleSearchUpdate}
                onChange={this.handleSearchChange}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.uuid}
                onKeyDown={this.handleSearchKeyDown}
                placeholder='Search'
              />
              <Button variant='outline-secondary' type='submit'><FaSearch/></Button>
            </ButtonGroup>
          </Form>
        </Navbar>
      );
    }
}