
import L from 'leaflet'
import React from 'react';

import "./LeafletMapWithMarkers.css";

export default class LeafletMapWithMarkers extends React.Component {

  makeMarker( locName, uuid, location, vlocs, markers, isObject ) {

    var redIcon = new L.Icon({
      iconUrl: '/assets/leaflet-markers/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    var blackIcon = new L.Icon({
      iconUrl: '/assets/leaflet-markers/marker-icon-2x-black.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

  var blueIcon = new L.Icon({
      iconUrl: '/assets/leaflet-markers/marker-blue.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 40],
      iconAnchor: [12, 40],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    if ( location && location['latitude'] && location['longitude'] ) {
      /** Position */
      var position = []
      position.push(location['latitude'])
      position.push(location['longitude'])

      /** Create the marker */
      var urlroot = isObject ? '/objects/' : '/assemblages/'
      var marker =
        L.marker( position, { title : locName, icon : blueIcon } )
              .bindPopup( '<a href="' + urlroot + uuid +'">' + locName + '</a>' );
      markers.push( marker );

      vlocs++;
    }

    return [ markers, vlocs ];
  }

  render() {
    /** Compute centre position and bounds from locations with valid env data */
    var vlocs = 0;
    var markers = [];

    if ( !this.props.props ) {
      return;
    }

    for ( var i = 0 ; i < this.props.props.length ; i++ ) {
        var o = this.props.props[i];
        if ( o.location && o.location.latitude && o.location.longitude ) {
          var isObject = true;
          if ( o.objects ) {
            isObject = false;
          }
          var result = this.makeMarker( o.name, o.uuid, o.location, vlocs, markers, isObject );

          markers = result[0];
          vlocs = result[1];
        }
    }

    var map;

    var mapHTML = document.getElementById('leaflet-container map');

    if ( mapHTML != null && this.props.props.length !== 0 ) {    // will load the map so long as the html that it needs and the locations are loaded
      if ( mapHTML.childElementCount < 2 ) {  // checks if the map has been initialised
        map = new L.map(mapHTML, {
          centre : [55, -4],
          zoom : 13
        });
      } else {                        // map is already initialised, so will break if constructor is called agian. This means that the map has to be reset to allow us to minipulate the map when its already loaded
        mapHTML.outerHTML = "<div id='leaflet-container map'></div>"
        map = L.map('leaflet-container map', {
          center : [55, -4],
          zoom : 13
        });
      }

      if ( map !== undefined ) {         // sets up the map so long as the map is actually there
        L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
          { attribution: '<a href="http://osm.org/copyright">OpenStreetMap</a> contributors' } ).addTo( map );

        for ( var it = 0; it < markers.length; it++ ) {
          markers[it].addTo( map )
        }

        if ( markers.length !== 0 ) {      // what sets the zoom and centre for the markers to all fit on screen by default
          var group = new L.featureGroup(markers);
          map.fitBounds(group.getBounds());
        }
      }
    }

    return (
      <React.Fragment>
        <div id="leaflet-container map" style={{width: '80%',height: '300px'}}></div>
      </React.Fragment>
      );                  // all we need to be returned as the javascript manipulates the map itself.
                          //  this was the problem before as it was hard to change the map when it was already rendered.
  }
}