
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './AboutPageView.css';

import { Col, Row } from 'react-bootstrap';

import axios from 'axios';

import Footer from './Footer.js';
import NavBar from './NavBar.js';

function DataRequestPageView( { props } ) {
  // RENDER

    const [ name, setName ] = useState( '' );
    const [ nameError, setNameError ] = useState( false );
    const [ email, setEmail ] = useState( '' );
    const [ emailError, setEmailError ] = useState( false );
    const [ message, setMessage ] = useState( '' );
    const [ messageError, setMessageError ] = useState( false );
    const [ formValid, setFormValid ] = useState( false );
    const [ mnemonic, setMnemonic ] = useState( '' );

    let location = useLocation();

    useEffect( () => {
        setMnemonic( location.pathname.replace('/datarequest/','') );
    }, [ location.pathname ] );
    
    const handleChange = e => {
        if ( e.target.name === 'name' ) {
            setName( e.target.value );
        } else {
            if ( e.target.name === 'email' ) {
                setEmail( e.target.value );
            } else {
                if ( e.target.name === 'message' ) {
                    setMessage( e.target.value );
                }
            }
        }
    };
  
    const handleSubmit = e => {

      setNameError( name ? false : true );
      setMessageError( message ? false : true );
      setEmailError( email ? false : true );
      
      if (name && email && message && !nameError && !emailError && !messageError) {
          setFormValid( true );

          var lstate = {
              'name' : name,
              'email' : email,
              'message' : message,
              'mnemonic' : mnemonic
          };

          axios.post( props.config.backendURL + '/datarequest', lstate )
              .then(function (response) {
              })
              .catch(function (error) {
                console.log(error);
                setFormValid( false );
              });
      } else {
          setFormValid( false );
      }

      e.preventDefault();      
    };
    
    return (
        <React.Fragment>
        <NavBar pathname={location.pathname}/>
        
        <div className='mainbody'>
          <Row>
          <Col xs={6}>
            <Row className="mt-2">
              <Col>
                <h2>Archaeoptics Data Request</h2>
              </Col>
            </Row>

            <Row>
              <Col>
              <b>Asset: {mnemonic}</b>
              <p/>
              </Col>
            </Row>

            <Row>
              <Col>
            Please fill out this form to submit a request for Archaeoptics data. You should
            explain your proposed use of the data in as much detail as possible for us to
            evaluate your request.
            <p/>
            If the IP Owner is not Archaeoptics, we will forward your request to the appropriate
            IP Owner who will contact you directly.
            <p/>
            Depending on your proposed use case, your request for data may not be approved.
              </Col>
            </Row>

            <Row>
            <Col>
              <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
                <div className="card-body">

                  { formValid && 
                   <div className="thankyou_details">
                     <div className="alert alert-success mt-3">Thank you for your data request. You will be contacted shortly. You should receive an acknowledgement email within 24 hours. If not, please resubmit your request.</div>
                   </div>
                 }

                  <form action="" onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data" autoComplete="off">
                    <div className="form-group">
                      <label className="mb-0">Your name<span className="text-danger">*</span></label>
                      <input name="name" type="text" className="form-control" placeholder="Name" value={name} onChange={handleChange} />
                      { nameError
                        ? <div className="alert alert-danger mt-2">Name is a required field.</div>
                        : ''
                      }
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Your email<span className="text-danger">*</span></label>
                      <input name="email" type="email" className="form-control" placeholder="Email" value={email} onChange={handleChange} />
                      { emailError
                        ? <div className="alert alert-danger mt-2">Email is a required field.</div>
                        : ''
                      }
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Message<span className="text-danger">*</span></label>
                      <textarea name="message" type="text" className="form-control" placeholder="Message" value={message} onChange={handleChange} />
                      { messageError
                        ? <div className="alert alert-danger mt-2">Message is a required field.</div>
                        : ''
                      }
                    </div>
                      <p className="text-center mb-0"><input type="submit" className="btn btn-primary btn-lg w-100 text-uppercase" value="Submit Now" /></p>
                  </form>
                </div>
              </div>
            </Col>
            </Row>
          </Col>
        </Row>
        </div>

        <Footer />
    </React.Fragment>
    );
};

export default DataRequestPageView;
