
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import './ArchiveAllPageView.css';

import { Alert, Row, Table } from 'react-bootstrap';
import { FaArrowsAltV, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import axios from 'axios';

import Footer from './Footer.js';
import NavBar from './NavBar.js';

import LeafletMapWithMarkers from './LeafletMapWithMarkers.js';

function ArchiveBaseListPageView( { props, dataProps } ) {

    const [ alertMessage, setAlertMessage ] = useState( '' );
    const [ alertNature, setAlertNature ] = useState( '' );
    const [ alertShow, setAlertShow ] = useState( false );

    const [ archiveList, setArchiveList ] = useState( [] );

    const [ sortBy, setSortBy ] = useState( 'name' );
    const [ sortFlipped, setSortFlipped ] = useState( false );

    let location = useLocation();
    let params = useParams();

    var title = useRef( dataProps.title );

    var isObjectList = true;
    if ( location['pathname'].includes( '/assemblages' ) ) {
        isObjectList = false;
    }

    useEffect( () => {

        var url = props.config.backendURL + dataProps.rootURL;

        if ( 'tag' in params ) {
            url = props.config.backendURL + '/tags/' + params.tag;
            title.current = 'Tag : ' + params.tag;
        } else {
            if ( 'siteowner' in params ) {
                url = props.config.backendURL + '/siteowner/' + params.siteowner;
                title.current = 'Site Owner: ' + params.siteowner;
            } else {
                if ( 'ipowner' in params ) {
                    url = props.config.backendURL + '/ipowner/' + params.ipowner;
                    title.current = 'IP Owner: ' + params.ipowner;
                } else {
                    if ( ':mnemonic1' in params ) {
                        var t = "/permalink/objects/:mnemonic0";
                        url = props.config.backendURL + '/permalink/objects/' + props.match.params.mnemonic0;
                    } else {
                        if ( ':mnemonic0' in params ) {
                            var t = "/permalink/objects/:mnemonic0/:mnemonic1";
                            url = props.config.backendURL + '/permalink/objects/' + props.match.params.mnemonic0 + '/' + props.match.params.mnemonic1;
                        }
                    }
                }
            }
        }

        axios.get( url )
          .then( res => {
            if ( res.status !== 200 ) {
              this.updateAlert( 'Failed to find list of archive summaries', 'danger' );
            } else {
              var result = res.data.result;

              /** Unravel sort date */
              for ( var i = 0 ; i < result.length ; i++ ) {
                  if ( result[i].sortdate ) {
                      var d = result[i].sortdate.split( "-" );
                      result[i].sortdate = new Date( d[2], d[1] - 1, d[0] );
                  }
              }

              setArchiveList( result );
            }
          } )
          .catch( error => {
            var lalertMessage = 'Something went wrong';

            if ( error.response ) {
              lalertMessage = error.response.data.message;
            }

            updateAlert( lalertMessage, 'danger' );
          } );
    }, [ props.config.backendURL ] );

  // BASIC METHODS
    const updateAlert = ( message, nature ) => {
      setAlertMessage( message );
      setAlertNature( nature );
      setAlertShow( true );
    }

    const closeAlert = e => {
      setAlertShow( false );
      setAlertMessage( '' );
      setAlertNature( '' );
    }

  // TABLE
    const getTable = e => {
        return (
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th className='archive-full-head' style={{width : '150px'}}>
                  Preview
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  Name {getFlipArrow( 'name' )}
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  Assemblage {getFlipArrow( 'assemblage' )}
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  Description {getFlipArrow( 'description' )}
                </th>
                <th onClick={handleColumnClick} className='archive-full-head' style={{minWidth : '100px'}}>
                  DD-MM-YYYY of scan(s) {getFlipArrow( 'sortdate' )}
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  Tag(s)
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  IP Owner {getFlipArrow( 'ipowner' )}
                </th>
                <th onClick={handleColumnClick} className='archive-full-head'>
                  Site Owner {getFlipArrow( 'siteowner' )}
                </th>
              </tr>
            </thead>
            <tbody>
              { archiveList.sort( ( a, b ) => ( ( a[sortBy] > b[sortBy] ) ^ sortFlipped ) ? 1 : -1 ).map( 
                  item => {
                    return(
                      <tr key={item.uuid} onClick={handleRowClick} id={item.uuid} className='archive-full-body'>
                        { item.previewimage ?
                          <td>
                            <img src={props.config.externalStaticURL + '/' + item.uuid + '/previewimages/' + item.previewimage.uuid + '.' + item.previewimage.suffix} alt="Preview of object" style={{ width : '100%', borderRadius : '7px'}}/>
                          </td>
                        :
                          <td>
                            Image Unavailable
                          </td>
                        }
                        <td>{item.name}</td>
                        <td>{ item.assemblageuuid !== '' &&
                            <a href={'/assemblages/' + item.assemblageuuid}>{item.assemblage}</a>
                          }
                        </td>
                        <td>{item.description}</td>
                        <td>{item.datesofscans}</td>
                        <td>
                          { item.tags.map(
                              tag => {
                                return (
                                  <div className='tag' key={item.uuid + tag}>
                                    <a href={'/tags/' + tag}>{tag}</a>
                                  </div>
                                );
                              }
                            )
                          }
                        </td>
                        <td><a href={'/ipowner/' + item.ipowner}>{item.ipowner}</a></td>
                        <td><a href={'/siteowner/' + item.siteowner}>{item.siteowner}</a></td>
                      </tr>
                    );
                  }
                )
              }
            </tbody>
          </Table>
        );
    };

    //
    const getFlipArrow = psortBy => {
        return (
          <React.Fragment>
            { psortBy === sortBy ?
              <React.Fragment>
                { sortFlipped ?
                  <FaArrowUp/>
                :
                  <FaArrowDown/>
                }
              </React.Fragment>
            :
              <FaArrowsAltV/>
            }
          </React.Fragment>
        );
    };

    //
    const handleRowClick = ( e, uuid ) => {
        if ( isObjectList ) {
            window.location.href = '/objects/' + e.currentTarget.id;
        } else {
            window.location.href = '/assemblages/' + e.currentTarget.id;          
        }
    };

    const handleColumnClick = e => {
      switch ( e.currentTarget.innerText ) {
        case 'Name ' :
          if ( sortBy === 'name' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'name' );
            setSortFlipped( false );
          }
          break;

        case 'Assemblage ' :
          if ( sortBy === 'assemblage' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'assemblage' );
            setSortFlipped( false );
          }
          break;

        case 'Description ' :
          if ( sortBy === 'description' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'description' );
            setSortFlipped( false );
          }
          break;

        case 'DD-MM-YYYY of scan(s) ' :
          if ( sortBy === 'sortdate' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'sortdate' );
            setSortFlipped( false );
                      }
          break;

        case 'Site Owner ' :
          if ( sortBy === 'siteowner' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'siteowner' );
            setSortFlipped( false );
          }
          break;

        case 'IP Owner ' :
          if ( sortBy === 'ipowner' ) {
            setSortFlipped( !sortFlipped );
          } else {
            setSortBy( 'ipowner' );
            setSortFlipped( false );
          }
          break;

        default :
          break;
      };
    };

  // RENDER
  return (
      <React.Fragment>
        <NavBar pathname={location.pathname} />
        { alertShow && 
          <div className='mainbody'>
            <Alert variant={alertNature} onClose={closeAlert} show={alertShow} dismissible>
              <p className='alertbody'>
                {alertMessage}
              </p>
            </Alert>
          </div>
        }
        <center>
        <h2>{title.current}</h2>
        </center>
        <Row>
          <LeafletMapWithMarkers props={archiveList} />
        </Row>
        {getTable()}
        <Footer />
      </React.Fragment>
    );
  };

export default ArchiveBaseListPageView;
