export function getAuthenticationState() {
  var userInfoSession = sessionStorage.getItem( 'userInfo' );
  if ( userInfoSession ) {
    userInfoSession = JSON.parse( userInfoSession );
    return { isAuthenticated : true, userInfo : userInfoSession }
  }

  return { isAuthenticated : false, userInfo : {} }
}

export function deauthenticateSession() {
  sessionStorage.removeItem( 'userInfo' );
}