import React from 'react';

import './ArchiveSearchPageView.css';

import { Alert, Card } from 'react-bootstrap';
import axios from 'axios';

import NavBar from './NavBar.js';

export default class ArchiveSearchPageView extends React.Component {
  // CLASS METHODS
    constructor( props, context ) {
      super( props, context );

      this.state = {};

      this.state['alertShow'] = false;
      this.state['alertMessage'] = '';
      this.state['alertNature'] = 'success';

      this.state['searchResults'] = [];
    }

    componentDidMount() {
      axios.get( this.props.config.backendURL + '/archive/search/' + this.props.match.params.searchquery )
        .then( res => {
          if ( res.status !== 200 ) {
            this.updateAlert( 'Failed to find list of archive summaries', 'danger' );
          } else {
            var result = res.data.result;

            this.setState( { searchResults : result } );
          }
        } )
        .catch( error => {
          var alertMessage = 'Something went wrong';

          if ( error.response ) {
            alertMessage = error.response.data.message;
          }

          this.updateAlert( alertMessage, 'danger' );
        } );
    }

  // BASIC METHODS
    updateAlert( message, nature ) {
      this.setState( { alertMessage : message, alertNature : nature, alertShow : true } );
    }

    closeAlert = e => {
      this.setState( { alertShow : false } );
    }

  // RESULTS BOXES 
    getResultsCard() {
      return(
        <React.Fragment>
          { this.state.searchResults.map(
              result => {
                return (
                  <Card 
                    style={{ width: '20rem' }} 
                    key={'search-result' + result.uuid} 
                    className='search-result-card'
                    id={result.uuid}
                    onClick={this.handleCardClick}>
                    { result.previewimage &&
                      <Card.Img 
                        variant="top" 
                        src={this.props.config.externalStaticURL + '/' + result.uuid + '/previewimages/' + result.previewimage.uuid + '.png'}
                        id={result.uuid}
                      />
                    }
                    <Card.Body>
                      <Card.Title>{result.name}</Card.Title>
                      <Card.Text>
                        {result.description}
                      </Card.Text>
                      <div style={{display : 'flex'}}>
                        { result.tags.map(
                            tag => {
                              return (
                                <div className='tag' key={result.uuid + tag} style={{marginRight : '5px'}}>
                                  {tag}
                                </div>
                              );
                            }
                          )
                        }
                      </div>
                    </Card.Body>
                  </Card>
                )
              }
            )
          }
        </React.Fragment>
      );
    }

    handleCardClick = e => {
      window.location.href = '/archive/' + e.currentTarget.id;
    }

  // RENDER
    render() {
      return (
        <React.Fragment>
          <NavBar />
          <div className='mainbody'>
            <Alert variant={this.state.alertNature} onClose={this.closeAlert} show={this.state.alertShow} dismissible>
              <p className='alertbody'>
                {this.state.alertMessage}
              </p>
            </Alert>
            <h1>Search results for '{this.props.match.params.searchquery}'</h1>
            <div>
              {this.getResultsCard()}
            </div>
          </div>
        </React.Fragment>
      );
    }
}