
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './StatisticsView.css';

import { Alert, Table, Row, Col } from 'react-bootstrap';
import { FaArrowsAltV, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import axios from 'axios';

import Footer from './Footer.js';
import NavBar from './NavBar.js';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, Sector} from 'recharts';
import { TagCloud } from 'react-tagcloud';

const options = {
      luminosity: 'light',
      hue: 'blue',
}

function StatisticsView( { props } ) {  

    const [ alertShow, setAlertShow ] = useState( false );
    const [ alertMessage, setAlertMessage ] = useState( '' );
    const [ alertNature, setAlertNature ] = useState( 'success' );

    const [ sortBy, setSortBy ] = useState( 'name' );
    const [ sortFlipped, setSortFlipped ] = useState( false );
    const [ activeIndex, setActiveIndex ] = useState( 0 );

    const [ archivedata, setArchiveData ] = useState( [ {'time' : 0, 'total' : 0 } ] );
    const [ jobdata, setJobData ] = useState( [] );
    const [ scandata, setScanData ] = useState( [] );
    const [ tagdata, setTagData ] = useState( [] );
    const [ stats, setStats ] = useState( {} );

    let location = useLocation();

    const onPieEnter = index => {
        setActiveIndex( index );
    };

    useEffect( () => {
        axios.get( props.config.backendURL + '/statistics/archives' )
          .then( res => {
            if ( res.status !== 200 ) {
              this.updateAlert( 'Failed to find list of archive counts', 'danger' );
            } else {
              var result = res.data.result;
              setArchiveData( result[0]['size'] );
              setJobData( result[0]['jobs'] );
              setTagData( result[0]['tags'] );
              setScanData( result[0]['objs'] );
              setStats( result[0]['stats'] );
            }
          } )
          .catch( error => {
            var lalertMessage = 'Something went wrong';

            if ( error.response ) {
              lalertMessage = error.response.data.message;
            }

            updateAlert( lalertMessage, 'danger' );
          } );
    }, [ props.config.backendURL ] );

  // BASIC METHODS
    const updateAlert = ( message, nature ) => {
      setAlertMessage( message );
      setAlertNature( nature );
      setAlertShow( true );
    }

    const closeAlert = e => {
      setAlertShow( false );
      setAlertMessage( '' );
      setAlertNature( '' );
    }

    const getArchiveGraph = e => {
      return (

            <React.Fragment>
              <ResponsiveContainer width={'99%'} height={300}>
                  <AreaChart
                    width={800}
                    height={400}
                    data={archivedata}
                    margin={{
                      top: 30,
                      right: 30,
                      left: 30,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" label={{ value: "Time",  position: 'bottom', offset: 0 }} />
                    <YAxis label={{ value: "Decompressed Archive Size (GB)", angle: -90,  position: 'insideBottomLeft', offset: 20 }}/>
                    <Tooltip />
                    <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
            </React.Fragment>
        )
    }



    const getJobsGraph = e => {
      return (

            <React.Fragment>
              <ResponsiveContainer width={'99%'} height={300}>
                  <BarChart
                    width={800}
                    height={400}
                    data={jobdata}
                    margin={{
                      top: 30,
                      right: 30,
                      left: 30,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" label={{ value: "Time",  position: 'bottom', offset: 0 }} />
                    <YAxis label={{ value: "Jobs per month", angle: -90,  position: 'insideBottomLeft', offset: 20 }}/>
                    <Tooltip />
                    <Bar dataKey="total" fill="#8884d8" />
                  </BarChart>
              </ResponsiveContainer>
            </React.Fragment>
        )
    }

    const getScanGraph = e => {
      return (

            <React.Fragment>
              <ResponsiveContainer width={'99%'} height={300}>
                  <BarChart
                    width={800}
                    height={400}
                    data={scandata}
                    margin={{
                      top: 30,
                      right: 30,
                      left: 30,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" label={{ value: "Time",  position: 'bottom', offset: 0 }} />
                    <YAxis label={{ value: "Scans per month", angle: -90,  position: 'insideBottomLeft', offset: 20 }}/>
                    <Tooltip />
                    <Bar dataKey="total" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
            </React.Fragment>
        )
    }

    const getTagCloud = e => {

      return (
        <React.Fragment>
          <TagCloud
            minSize={12}
            maxSize={35}
            tags={tagdata}
            colorOptions={options}
            onClick={tag => window.location.href='/tags/' + tag.value}
          />
        </React.Fragment>


      )


    }

    const getWordsWithStats = e => {
      var objTotal = 'objects' in stats ? String(stats['objects']) : '0';
      var assemblagesTotal = 'assemblages' in stats ? String(stats['assemblages']) : '0';
      var scansTotal = 'scans' in stats ? String(stats['scans']) : '0';
      var archiveSize = 'total' in archivedata[archivedata.length -1 ] ? archivedata[archivedata.length -1 ]["total"].toFixed(2) : '0'
      var startdate = 'time' in archivedata[0] ? archivedata[0]["time"]: 'unknown'

      return (
        'The archive comprises of '+objTotal+' unique objects and '+assemblagesTotal+ ' assemblages. The full decompressed archive is currently '+archiveSize+ 'GB in size, relating to '+scansTotal+ ' scans since '+startdate+'.'

      )

    }

  // RENDER
    return (
      <React.Fragment>
        <NavBar pathname={location.pathname}/>
        { alertShow && 
          <div className='mainbody'>
            <Alert variant={alertNature} onClose={closeAlert} show={alertShow} dismissible>
              <p className='alertbody'>
                {alertMessage}
              </p>
            </Alert>
          </div>
        }
        <div className='mainbody'>
          <h2>Archive Statistics </h2>

          {getWordsWithStats()}

          <div className='graph-container'>
              <Row>
              <Col>
              {getArchiveGraph()}
              </Col>
              </Row>
              <Row>
              <Col>
              {getJobsGraph() }
              </Col>
              </Row>
              <Row>
              <Col>
              {getScanGraph() }
              </Col>
              </Row>
          </div>
          <Row>
          <Col xs={1}></Col>
          <Col xs={10}>
          {getTagCloud()}
          </Col>
          </Row>
        </div>

        <Footer />
      </React.Fragment>
    );
};

export default StatisticsView;
